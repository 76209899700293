import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const RichTextContent = ({ richTextContent }) => {
    // Предполагается, что richTextContent - это данные из Contentful
    const options = {
        renderNode: {
            'paragraph': (node, children) => <p style={{ whiteSpace: 'pre-line' }}>{children}</p>,
        },
    }

    return <div>{documentToReactComponents(richTextContent, options)}</div>;
};

export default RichTextContent;
