import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import aboutImage from "images/about_page.jpg";

const About = () => {

    return (
        <Container className="my-5">
            <Row className="justify-content-md-center d-flex align-items-center justify-content-center">
                <Col xs={12} lg={7}>
                    <h2>Wine 4 Oysters Bar & Bites</h2>
                    <h3>A Culinary Celebration of Oysters</h3>
                    <p>
                        Welcome to Wine 4 Oysters Bar & Bites, where our love for oysters isn't just a taste preference, it's a journey of culinary discovery! For 17 years, we've been exploring the globe, handpicking the most exquisite oysters, each reflecting the unique character of its homeland.
                    </p>
                    <p>
                        Our quest has taken us to:
                    </p>

                    <ul>
                        <li>The sophisticated shores of France, where oysters are dressed in a classic vinaigrette.</li>
                        <li>The mystical lands of Japan, home to miso-steamed oyster wonders.</li>
                        <li>The bustling bays of Sydney, Hong Kong, Oregon, Chesapeake Bay, New Orleans, and our very own sunshine state, Florida, each offering its distinct oyster flavors.</li>
                    </ul>

                    <p>
                        This voyage is far from over. Our enthusiasm drives us to constantly seek out new flavors, to dive into the diverse culinary stories each oyster region has to tell.
                    </p>
                    <p>
                        Whether you're joining us for an immersive dining experience, or bringing a piece of our oyster haven into your home, we're here to provide. Planning a special event? Let us bring the magic of our oyster journey to your celebration, creating memories as rich as our history.
                    </p>
                    <p>
                        Here's to the untold tales, the memories yet to be cherished, and the myriad of flavors still waiting to be discovered. To the world of oysters and the endless delights they offer!
                    </p>
                    <p>
                        Cheers,<br/>
                        <b><i>The Wine 4 Oysters Team</i></b>
                    </p>
                </Col>
                <Col xs={12} lg={5}>
                    <img src={aboutImage} alt="About us" className="w-100 rounded-3"/>
                </Col>
            </Row>
        </Container>
    );
};

export default About;
