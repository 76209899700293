import React, {useState, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import {getGallery} from "utils/getContent";
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/style.css';

import './index.scss'

const GalleryComponent = () => {
    const [images, setImages] = useState([]);

    // Загрузка картинок при монтировании компонента
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imageUrls = await getGallery(); // Используем обертку для получения картинок
                setImages(imageUrls);
                console.log('images', imageUrls);
            } catch (error) {
                console.error('Ошибка при загрузке изображений:', error);
            }
        };

        fetchImages();
    }, []);


    const options = {
        zoom: false,
        counter: false,
        // bgOpacity: 0.2,
        spacing: 0.5,
        padding: { top: 20, bottom: 40, left: 50, right: 50 },
    }

    return (
        <Container className="my-5 align-content-center">
            <Gallery options={options}>
                <div className="gallery-div">
                    {images.map(({url, width, height}, idx) =>
                        <Item
                            original={`${url}?w=800`}
                            thumbnail={`${url}?w=100`}
                            width={width}
                            height={height}
                            key={idx}
                        >
                            {({ ref, open }) => (
                                <img
                                    ref={ref}
                                    onClick={open}
                                    src={`${url}?w=200`}
                                    alt=''
                                    className="preview-img rounded-3"
                                />
                            )}
                        </Item>
                    )}
                </div>
            </Gallery>
        </Container>
    );
};

export default GalleryComponent;
