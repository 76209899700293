import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import reservationImage from 'images/reservation.png';
import RestaurantReservationForm from "components/BookTable";
import NewsSlider from "components/NewsSlider";
import {Link} from "react-router-dom";
import {Facebook, TelephoneFill} from "react-bootstrap-icons";

import './index.scss';
import About from "../About";

const Home = ({restaurantsList}) => {


    return (
        <Container fluid>
            {/* рестораны */}
            <Container className="restaurants-block">
                <Row className="justify-content-md-center">
                    <Col xs={12} lg={6}>
                        <div className="title-block">Come and taste probably the best oyster bar in Central
                            Florida
                        </div>
                    </Col>
                    {/* Сделать кнопки fb, звонка и маршрута  */}
                    {restaurantsList.map((restaurant, idx) =>
                        <Col xs={12} md={6} lg={3} key={restaurant.id}>
                            <div className="restaurant">
                                <Link to={`/menu?restaurant=${idx === 0 ? 'east' : 'west'}`} className="text-decoration-none">
                                    <div className="image-container">
                                        <img src={restaurant.photo.fields.file.url + '?w=200'} alt={`Restaurant ${restaurant.title}`} className="rounded-3 restaurant-img" />
                                        <div className="overlay">
                                            <div className="overlay-content">
                                                <p>Explore Our Menu</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="text-body text-center">{restaurant.title}</h3>
                                </Link>
                                <div className="d-flex justify-content-center align-items-center my-4">
                                    <a href={`tel:${restaurant.phone}`}><TelephoneFill size={28} /></a>
                                    <span className="mx-5"></span>
                                    <a href={restaurant.linkFacebook} target="_blank" rel="noreferrer">
                                        <Facebook size={28} color="blue"/>
                                    </a>
                                </div>

                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
            {/* бронирование столика */}
            <Container fluid className="reservation-block">
                <Container>
                    <Row className="justify-content-md-center d-flex align-items-center justify-content-center">
                        <Col xs={12} lg={6} className="d-none d-sm-block">
                            <img src={reservationImage} alt="Reservation table"/>
                        </Col>
                        <Col xs={12} lg={6}>
                            <div className="description-part d-none">Booking a table online is easy</div>
                            <RestaurantReservationForm restaurants={restaurantsList}/>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* о ресторане */}
            <About />

            {/* новости */}
            <Container fluid className="news-block">
                <Container>
                    <Row className="justify-content-md-center">
                        <NewsSlider/>
                    </Row>
                </Container>
            </Container>


        </Container>
    );
};

export default Home;
