import React from 'react';
import './index.scss';

const BlogPostShort = ({ imageSrc, title, text, blogId }) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {/* Картинка справа */}
            <img src={`${imageSrc}?w=150`} alt="Blog Post" style={{ width: '10rem', height: '10rem', marginRight: '1rem', borderRadius: '1rem' }} />

            {/* Заголовок и текст слева */}
            <div>
                <h3 className="blog-title"><a href={`/blog/${blogId}/`} title='Read more' className="nav-link">{title}</a></h3>
                {text}
                <br/>
                <a href={`/blog/${blogId}/`} title='Read more' style={{ display: 'flex', alignItems: 'right', marginTop: '1rem' }}>Read more</a>
            </div>
        </div>
    );
};

export default BlogPostShort;
