import React, {useState, useEffect} from 'react';
import {Container} from 'react-bootstrap';
import {getBlog} from "utils/getContent";

import './index.scss'
import BlogPostShort from "../BlogPostShort";

const BlogComponent = () => {
    const [blogs, setBlogs] = useState([]);

    // Загрузка картинок при монтировании компонента
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const blogList = await getBlog({}); // Используем обертку для получения картинок
                // setImages(imageUrls);
                setBlogs(blogList.items);
                console.log('blog', blogList);
                console.log('img', blogList.items[0].fields.images[0].fields.file.url);
                console.log('img', blogList.items[0].fields.shortText);
            } catch (error) {
                console.error('Ошибка при загрузке изображений:', error);
            }
        };

        fetchBlogs();
    }, []);


    return (
        <Container className="my-5 align-content-center">
            <h1 className='page-title'>Our blog</h1>
            <hr/>
            {blogs.map((blog) => {
                return (
                    <BlogPostShort
                        title={blog.fields.title}
                        text={blog.fields.shortText}
                        imageSrc={blog.fields.images[0].fields.file.url}
                        key={blog.sys.id}
                        blogId={blog.fields.friendlyUrl}
                    />
                );
            })}
        </Container>
    );
};

export default BlogComponent;
