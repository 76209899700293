import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'images/logo.png'
import './index.scss';

const Footer = () => {
    return (
        <Container fluid className="footer mt-3 p-5">
            <Row className="ms-5">
                <Col xs={12} md={6}>
                    <img src={logo} alt="Logo" className="footer-logo" />  {/* Ensure you have a logo.png file in /public/images */}
                </Col>
                <Col xs={12} md={6}>
                    <ul className="footer-links">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/menu">Menu</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} className="text-end">
                    <p className="copyright">&copy; {new Date().getFullYear()} Wine4Oysters. Developed by <a href="https://samigulin.com" target="_blank" rel="noreferrer">Innovation Horizon</a></p>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
