import React from "react";
import {Tabs, Tab} from 'react-bootstrap';
import {Container, Col, Row} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";
import {FiletypePdf} from "react-bootstrap-icons";

// preview menu
import previewMenuEast from 'images/preview-menu/preview-menu-east.jpeg'
import previewDrinkEast from 'images/preview-menu/preview-drink-east.jpeg'
import previewMenuWest from 'images/preview-menu/preview-menu-west.jpeg'
import previewBrunchWest from 'images/preview-menu/preview-brunch-west.jpeg'
import previewDrinkWest from 'images/preview-menu/preview-drink-west.jpeg'

const getMenuPath = (menuName) => {
    return `${process.env.PUBLIC_URL}/menu_files/${menuName}`
}

const Menu = () => {
    const [searchParams] = useSearchParams();

    // Получение значения параметра URL
    const restaurant = searchParams.get('restaurant');

    return (
        <Container>
            <Tabs
                defaultActiveKey={restaurant === 'west' ? 'west' : 'east'}
                className="mt-3 mb-3"
                justify
            >
                <Tab eventKey="east" title="East Orlando">
                    <Container>
                        <Row className="mt-5">
                            <Col xs={12} md={6} xl={4} className="text-center mb-3">
                                <h4>
                                    <a href={getMenuPath('menu-east.pdf')} target="_blank" title="Menu for food East" rel="noreferrer">
                                        <img src={previewMenuEast} alt="preview for menu East"/>
                                        <br/>
                                        <FiletypePdf /> East food menu
                                    </a>
                                </h4>
                            </Col>

                            <Col xs={12} md={6} xl={4} className="text-center mb-3">
                                <h4>
                                    <a href={getMenuPath('drink-east.pdf')} target="_blank" title="Menu for drinks East" rel="noreferrer">
                                        <img src={previewDrinkEast} alt="preview for drinks East"/>
                                        <br/>
                                        <FiletypePdf /> East drink menu
                                    </a>
                                </h4>
                            </Col>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="west" title="West Orlando">
                    <Container>
                        <Row className="mt-5">
                            <Col xs={12} md={6} className="text-center mb-3">
                                <h4>
                                    <a href={getMenuPath('menu-west.pdf')} target="_blank" title="Food menu West" rel="noreferrer">
                                        <img src={previewMenuWest} alt="preview for menu West"/>
                                        <br/>
                                        <FiletypePdf /> West food menu
                                    </a>
                                </h4>
                            </Col>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <h4>
                                    <a href={getMenuPath('brunch-west.pdf')} target="_blank" title="Brunch menu West" rel="noreferrer">
                                        <img src={previewBrunchWest} alt="preview for brunch menu West"/>
                                        <br/>
                                        <FiletypePdf /> Brunch menu
                                    </a>
                                </h4>
                            </Col>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <h4>
                                    <a href={getMenuPath('drink-west.pdf')} target="_blank" title="Drinks menu West" rel="noreferrer">
                                        <img src={previewDrinkWest} alt="preview for drinks menu West"/>
                                        <br/>
                                        <FiletypePdf /> West drink menu
                                    </a>
                                </h4>
                            </Col>
                        </Row>
                    </Container>
                </Tab>
            </Tabs>
        </Container>
    )
};

export default Menu;
