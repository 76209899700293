import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {Container, Spinner} from 'react-bootstrap';
import {getBlogById} from "utils/getContent";
import RichTextContent from "../RichTextContent";

import './index.scss'

const BlogComponent = () => {
    const [blogInfo, setBlogInfo] = useState(undefined);
    const {blogId} = useParams();


    // Загрузка картинок при монтировании компонента
    useEffect(() => {
        const fetchBlogById = async () => {
            try {
                const blogList = await getBlogById({blogId}); // Используем обертку для получения картинок
                // setImages(imageUrls);
                setBlogInfo(blogList.items[0].fields);
                console.log('blog', blogList.items[0].fields);
            } catch (error) {
                console.error('Ошибка при загрузке изображений:', error);
            }
        };

        fetchBlogById();
    }, [blogId]);


    if (!blogInfo) {
        // Если информация еще не загружена, отобразить лоадер
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }


    return (
        <Container className="my-5 align-content-center">
            {/* Заголовок статьи */}
            <h1>{blogInfo?.title}</h1>

            {/* Картинка по середине на всю ширину, но не больше самой картинки */}
            <div style={{textAlign: 'center'}}>
                <img
                    src={`${blogInfo?.images[0].fields.file.url}?w=300`}
                    alt="Blog Post"
                    style={{width: 'auto', maxWidth: '100%', height: 'auto'}}
                />
            </div>

            {/* Текст статьи */}
            <div style={{marginTop: '20px'}}>
                <RichTextContent richTextContent={blogInfo?.fullText} />
            </div>
        </Container>
    );
};

export default BlogComponent;
