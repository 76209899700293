import React, {useState} from 'react';
import {Container, Row, Col, Form, Button, Alert} from 'react-bootstrap';
import './index.scss';

const RestaurantReservationForm = ({restaurants}) => {
    const [selectedRestaurant, setSelectedRestaurant] = useState('');
    const [numberOfSeats, setNumberOfSeats] = useState('1');
    const [dateTime, setDateTime] = useState('');
    const [showPhoneButton, setShowPhoneButton] = useState(false);

    const handleRestaurantSelection = (restaurantId) => {
        setSelectedRestaurant(restaurantId);
        setShowPhoneButton(numberOfSeats === '7+')
    };

    const handleSeatSelection = (seats) => {
        setNumberOfSeats(seats);
        setShowPhoneButton(seats === '7+');
    };

    const getCurrentRestaurant = (currentRestaurant) => restaurants.find(restaurant => restaurant.id === currentRestaurant)

    const handleDateTimeChange = (e) => {
        // const formattedDateTime = e.target.value.replace('T', ' ');
        setDateTime(e.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Действия при отправке формы, например, отправка данных на сервер
        if (numberOfSeats === '7+') {
            setShowPhoneButton(true);
        } else {
            const dateObj = new Date(dateTime);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            const formattedTime = dateObj.toLocaleTimeString('en-US', { hour12: false });



            const url = `https://getseat.net?date=${formattedDate}&party_size=${numberOfSeats}&time=${formattedTime}&channel=merchant_web#/public/online/reservation/${getCurrentRestaurant(selectedRestaurant).id}`;
            window.open(url, '_blank');
        }
    };

    const handleCallRestaurant = (phone) => {
        window.location.href = `tel:${phone}`;
    };

    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="restaurantName">
                            {restaurants.map((restaurant) => (
                                <Button
                                    key={restaurant.id}
                                    variant={selectedRestaurant === restaurant.id ? 'warning' : 'outline-warning'}
                                    onClick={() => handleRestaurantSelection(restaurant.id)}
                                    className="me-2 mb-2 select-restaurant"
                                >
                                    {restaurant.title}
                                    {selectedRestaurant === restaurant.id && ' ✔'}
                                </Button>
                            ))}
                        </Form.Group>

                        <Form.Group controlId="numberOfSeats">
                            <Form.Label>Party size</Form.Label>
                            <Form.Control
                                as="select"
                                value={numberOfSeats}
                                onChange={(e) => handleSeatSelection(e.target.value)}
                                disabled={selectedRestaurant === ''}
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7+">7+</option>
                            </Form.Control>
                        </Form.Group>

                        {showPhoneButton ? (
                            <>
                                <Alert variant="warning mt-3">
                                    For the parties 7+ please call the restaurant during operating hours.
                                </Alert>
                                <Button
                                    variant="primary"
                                    onClick={() => handleCallRestaurant(getCurrentRestaurant(selectedRestaurant).phone)}
                                >
                                    Call: {getCurrentRestaurant(selectedRestaurant).phone}
                                </Button>
                            </>
                        ) : (
                            <Form.Group controlId="dateTime">
                                <Form.Label>Date and time</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    min={new Date().toISOString().split('T')[0]}
                                    value={dateTime}
                                    onChange={handleDateTimeChange}
                                    disabled={selectedRestaurant === ''}
                                />
                            </Form.Group>
                        )}

                        {!showPhoneButton && (<div>
                            <br/>
                            <Button variant="warning" type="submit">
                                Book
                            </Button>
                        </div>)}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default RestaurantReservationForm;
