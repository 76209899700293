import React, {useEffect, useState} from 'react';
import {Badge, Carousel, Col, Row} from 'react-bootstrap';
import './index.scss';
import {getNews} from "../../utils/getContent";
import RichTextContent from "../RichTextContent";

const NewsSlider = () => {
    const [newsList, setNewsList] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            const data = await getNews();
            const newsArray = data.map(news => ({
                date: news.fields.dateEvent,
                description: news.fields.description.content || [],
                title: news.fields.title,
                image: news.fields.image?.fields?.file || null,
                restaurants: news.fields.restaurant
            }));
            setNewsList(newsArray);
        };

        fetchData().catch(e => console.log('on get news error', e));
    }, []);


    return (
        <Carousel interval={null} indicators={false} variant="dark" className="news-carousel">
            {newsList.map((item, index) => (
                <Carousel.Item key={index}>
                    <Row>
                        <Col xs={{span: 8, offset: 2}} md={{span: 2, offset: 2}} className="block-date">
                            <span className="big-date">
                                {new Date(item.date).toLocaleString('en', {day: '2-digit'})}
                            </span>
                            <span>{new Date(item.date).toLocaleString('en', {month: 'long'})}</span>
                            <span>{item.time}</span>


                            <div>
                                {item.restaurants.map((restaurant, index) => (
                                    <div key={index} className="current-restaurant">
                                        <Badge bg="warning" className="me-2" text="dark">
                                            {restaurant.fields.title}
                                        </Badge>
                                    </div>
                                ))}
                            </div>
                        </Col>
                        <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>
                            <div className="news-item">
                                <h3>{item.title}</h3>

                                {item?.description?.map((newsItem, idx) => (
                                    <RichTextContent key={idx} richTextContent={newsItem} />
                                ))}

                                {item?.image && <img src={`${item.image?.url}?w=350`} alt={item.image?.fileName} className="news-img"/> }
                            </div>
                        </Col>
                    </Row>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default NewsSlider;
