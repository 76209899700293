import React from 'react';
import { Container } from 'react-bootstrap';

function NotFound() {
    return (
        <Container>
            <h1>404 Not Found</h1>
        </Container>
    );
}

export default NotFound;
