import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import {Instagram} from 'react-bootstrap-icons';
import logo from 'images/logo.png';
import './index.scss';

const Header = () => {
    const location = useLocation();
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);

    const links = [
        {
            title: 'Best Oysters in Orlando | Seafood Restaurants in Orlando',
            menuName: 'Home',
            description: 'Wine4Oysters serves the best oysters in Orlando. Explore our wide range of cuisines and various seafood at one of finest seafood restaurants in Orlando.',
            route: '/'
        },
        {
            title: 'Menu | Brunch Restaurant in Orlando | Seafood Restaurant Orlando',
            menuName: 'Menu',
            description: 'For an exquisite brunch in Orlando, with a great range of fresh seafoods and Enjoy the amazing wines from one of the best wine bar in Orlando.',
            route: '/menu'
        },
        {
            title: 'About us | Wine4Oysters Orlando',
            menuName: 'About us',
            description: 'Wine4Oysters is a restaurant that specializes in oysters but also serves other fresh seafood in Orlando. We offer some of the best oysters in Orlando.',
            route: '/about'
        },
        {
            title: 'Gallery | Wine4Oysters Orlando',
            menuName: 'Gallery',
            description: 'Try delicious fresh Oysters at Wine4oysters, The best oyster bar in Orlando. Wine4Oysters additionally provides a variety of fresh seafood.',
            route: '/gallery'
        },
        {
            title: 'Blog | Wine4Oysters Orlando',
            menuName: 'Blog',
            description: 'Try delicious fresh Oysters at Wine4oysters, The best oyster bar in Orlando. Wine4Oysters additionally provides a variety of fresh seafood.',
            route: '/blog'
        },
        // {
        //     title: 'Catering | Wine4Oysters Orlando',
        //     menuName: 'Catering',
        //     description: 'Wine4Oysters is one of the leading brunch restaurants in Orlando, where you can get tastiest oysters and shrimps in that nearby location.',
        //     route: '/catering'
        // },
        {
            title: 'Contact | Wine4Oysters Orlando',
            menuName: 'Contact',
            description: 'Wine4Oysters.com offers a large selection of fresh oysters. Join us for delicious oysters in Orlando restaurants for a fantastic oyster experience.',
            route: '/contact'
        },
    ];

    const getPageTitle = () => {
        const link = links.find((item) => item.route === location.pathname);
        return link ? link.title : 'Page not found';
    };

    const getMetaDescription = () => {
        const link = links.find((item) => item.route === location.pathname);
        return link ? link.description : 'Wine4Oysters serves the best oysters in Orlando. Explore our wide range of cuisines and various seafood at one of finest seafood restaurants in Orlando.';
    };

    const handleMenuToggle = () => {
        setIsMenuExpanded(!isMenuExpanded);
    };

    const ogUrl = `${window.location.origin}${location.pathname}`;


    return (
        <header>
            <div className='top-header'>
                <div className="container">
                    Happy Hour Monday-Friday 4-6pm 30% OFF! OYSTERS, JAMES RIVER, VA / OYSTER SHOOTER / CLASSIC COCKTAILS
                    <br/>
                    $2 OFF DRAFT BEER & WINE by the GLASS
                </div>
            </div>
            <div className='middle-header sticky-top'>
                <Navbar variant="light" expand="lg">
                    <Container>
                        <Navbar.Brand className="col-3 col-md-2 logo">
                            <Link to="/">
                                <img src={logo} alt="Wine 4 Oysters logo"/>
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-nav" onClick={handleMenuToggle}/>
                        <Navbar.Collapse id="navbar-nav" className={isMenuExpanded ? "show" : ""}>
                            <Nav className="col-md-10 justify-content-center">
                                {links.map((item, id) => (
                                    <Nav.Item key={id}>
                                        <Link to={item.route} className="nav-link">{item.menuName}</Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <Nav className="ms-auto">
                                <Nav.Item>
                                    <Nav.Link href="https://www.instagram.com/wine4oysters/"
                                              target="_blank"
                                              style={{whiteSpace: 'nowrap'}}
                                              title="Our Instagram">
                                        <Instagram size={24}/> Wine4Oysters
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>

            <Helmet>
                <title>{getPageTitle(location.pathname)}</title>
                <meta name="description" content={getMetaDescription()}/>
                <meta property="og:title" content={getPageTitle()}/>
                <meta property="og:url" content={ogUrl}/>
            </Helmet>
        </header>
    );
};

export default Header;
