import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from "react-helmet-async";
import Home from 'components/Home';
import About from 'components/About';
import NotFound from 'components/NotFound';
import Header from "components/Header";
import Footer from "components/Footer";
import Menu from "components/Menu";
import Contacts from "components/Contact";
import Gallery from "components/Gallery";
// import Catering from "components/Catering";
import Blog from "./components/Blog";

import BlogPostFull from "./components/BlogPostFull";
import {getRestaurants} from "./utils/getContent";

const App = () => {
    const [restaurantsList, setRestaurantsList] = useState([]);

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const restaurantsList = await getRestaurants().then(res => {
                    return res.map(i => i.fields);
                });
                setRestaurantsList(restaurantsList);
            } catch (error) {
                console.error('Ошибка при загрузке ресторанов:', error);
            }
        };

        fetchRestaurants();
    }, []);


    return (
        <BrowserRouter>
            <HelmetProvider>
                <div className="app-container">
                    <Header/>
                    <main className="content">
                        <Routes>
                            <Route path="/" element={<Home restaurantsList={restaurantsList}/>}/>
                            <Route path="/menu" element={<Menu/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/gallery" element={<Gallery/>}/>
                            <Route path="/blog" element={<Blog/>}/>
                            <Route path="/blog/:blogId/" element={<BlogPostFull/>}/>
                            {/*<Route path="/catering" element={<Catering/>}/>*/}
                            <Route path="/contact" element={<Contacts restaurantsList={restaurantsList}/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    </main>
                    <Footer/>
                </div>
            </HelmetProvider>
        </BrowserRouter>
    );
};

export default App;
