import {createClient} from 'contentful';

const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});


export const getRestaurants = () => {
    return client.getEntries({
        content_type: 'restaurants',
        locale: '',
    })
        .then((response) => {
            // Обработка полученных записей

            return response.items;
        })
        .catch(console.error);
}

export const getNews = () => {
    return client.getEntries({content_type: 'news'})
        .then((response) => {
            // Обработка полученных записей

            return response.items;
        })
        .catch(console.error);
}

export const getBlog = ({page = 1, count = 10}) => {
    return client.getEntries({
        content_type: 'blog',
        limit: count,
        order: 'sys.createdAt',
    })
        .then((response) => {
            // Обработка полученных записей

            console.log('response', response)
            return response;
        })
        .catch(console.error);
}

export const getBlogById = ({blogId}) => {
    return client.getEntries({
        content_type: 'blog',
        'fields.friendlyUrl': blogId,
        limit: 1,
        order: 'sys.createdAt',
    })
        .then((response) => {
            // Обработка полученных записей

            console.log('response', response)
            return response;
        })
        .catch(console.error);
}

export const getGallery = () => {
    return client.getEntries({content_type: 'gallery'})
        .then((response) => {
            // Обработка полученных записей

            const images = response.items[0].fields.image; // Предполагается, что в типе контента "Gallery" есть поле "images" со списком картинок
            return images.map((image) => {
                return {
                    url: image.fields.file.url,
                    width: image.fields.file.details.image.width,
                    height: image.fields.file.details.image.height,
                }
            });
        })
        .catch(console.error);
}
